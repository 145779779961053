<script>
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- CSS -->
<Section>
  <p>I like writing raw CSS and SCSS.</p>
  <p>
    But I also like Tailwind CSS, so it is often the case that I mix the two.
  </p>
  <div class="pt-4"></div>
  <p>
    I usually start up with Tailwind and gradually add any raw CSS that Tailwind
    can't express in a simple manner.
  </p>
  <div class="pt-4"></div>
  <p>
    One such example would be detailed management of grids and grid areas, which
    I like to use a lot for layouts.
  </p>
  <div class="pt-4"></div>
  <p>
    More often than not I reach out for SCSS for convenience, but I have no
    issues writing traditional CSS.
  </p>
  <div class="pt-4"></div>
  <Skills
    skills={[
      {
        name: 'Tailwind',
        image:
          'https://tailwindcss.com/_next/static/media/tailwindcss-mark.3c5441fc7a190fb1800d4a5c7f07ba4b1345a9c8.svg',
        usage: 'primary',
        familiarity: 'high',
      },
      {
        name: 'Materialize',
        image: 'https://materializecss.com/res/materialize.svg',
        usage: 'secondary',
        familiarity: 'high',
      },
      {
        name: 'Pico',
        image:
          'https://raw.githubusercontent.com/picocss/pico/HEAD/.github/logo-light.svg',
        usage: 'secondary',
        familiarity: 'medium',
      },
      {
        name: 'Bootstrap',
        image:
          'https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg',
        usage: 'secondary',
        familiarity: 'medium',
      },
    ]}
  />
</Section>
