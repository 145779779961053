<script>
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- JavaScript / TypeScript -->
<Section>
  <p>
    For better or worse, JavaScript is the language of the web as of today, and
    it deserves the spot.
  </p>
  <br />
  <p>
    Generally speaking I can write any type of JavaScript, from Angular to Vue
    and Svelte.
  </p>
  <div class="pt-4"></div>
  <p>
    Static analysis is a must for me while writing <b>JavaScript</b>, which
    implies <b>TypeScript</b>.
  </p>
  <div class="pt-4"></div>
  <p>
    Although I love what TypeScript brings to JavaScript, I've grown a bit
    distant from TypeScript and I've embraced <b>JSDoc</b> completely.
  </p>
  <div class="pt-4"></div>
  <p>
    There are many reasons as to why I decided to stop promoting/writing
    TypeScript, but the main reason would be <b>faster build times</b>.
  </p>
  <div class="pt-4"></div>
  <Skills
    title="Runtime"
    skills={[
      {
        name: 'Bun',
        familiarity: 'high',
        usage: 'primary',
        image: 'https://bun.sh/logo.svg',
      },
      {
        name: 'Node',
        familiarity: 'high',
        usage: 'secondary',
        image: 'https://nodejs.org/static/logos/jsIconGreen.svg',
      },
      {
        name: 'Deno',
        familiarity: 'medium',
        usage: 'secondary',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Deno_2021.svg/1280px-Deno_2021.svg.png',
      },
    ]}
  />
</Section>
