<script>
  /**
   * @typedef Skill
   * @property {string} name
   * @property {"high"|"medium"} familiarity
   * @property {"primary"|"secondary"} usage
   * @property {string} image
   * @property {boolean} [light]
   */

  /** @type {Array<Skill>} */
  export let skills
  export let title = 'Framework'
  export let light = false
</script>

<table class="table">
  <thead>
    <tr class:text-black={light}>
      <th>{title}</th>
      <th>Familiarity</th>
    </tr>
  </thead>
  <tbody>
    {#each skills as skill}
      <tr>
        <td>
          <div class="flex items-center gap-3">
            <div class="avatar">
              <div
                class="mask mask-squircle w-12 h-12"
                class:bg-black={skill.light}
                class:p-1={skill.light}
                class:pt-2={skill.light}
                class:pb-1.5={skill.light}
              >
                <img
                  src={skill.image}
                  alt="Logo of {skill.name.toLocaleUpperCase()}"
                />
              </div>
            </div>
            <div>
              <div class="font-bold">{skill.name.toLocaleUpperCase()}</div>
              <div class="text-sm opacity-50">
                {skill.usage.toLocaleUpperCase()}
              </div>
            </div>
          </div>
        </td>
        <td>{skill.familiarity.toLocaleUpperCase()}</td>
      </tr>
    {/each}
  </tbody>
</table>
