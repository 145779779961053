<script>
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- Java -->
<Section>
  <p>
    Java is one of the first languages I've ever learned.<br />
    Although many despise it for it's boilerplate-y nature, the extensive standard
    library somewhat makes up for it.
  </p>
  <div class="pt-4"></div>
  <p>
    When in comes specifically to web development, my frameworks of choice is
    <b>Quarkus</b>
    or
    <b>Spring Boot</b>.
  </p>
  <div class="pt-4"></div>
  <Skills
    title="VM"
    skills={[
      {
        name: 'Graal',
        usage: 'primary',
        familiarity: 'high',
        image:
          'https://www.graalvm.org/resources/img/home/graalvm_rabbit_icon.svg',
      },
      {
        name: 'Hotspot',
        usage: 'secondary',
        familiarity: 'high',
        image:
          'https://www.liblogo.com/img-logo/max/ja362j8d5-java-logo-java-logo-transparent-png-stickpng.png',
      },
    ]}
  />
</Section>
