<script>
  import Section from ':components/section.svelte'
</script>

<!-- Zig -->
<Section>
  <p>
    Zig is an imperative, general-purpose, statically typed, compiled system
    programming language designed by Andrew Kelley.<br />It is intended as a
    successor to the language C.
  </p>
  <div class="pt-4"></div>
  <p>
    Zig is my language of choice when it comes to systems programming.<br />
    It is simple, reliable and can build for any target you can think of from any
    target you can think of.
  </p>
  <p>
    Are you running Linux and you need to build for Windows? No problem, Zig
    does it.<br />
    Are you running Windows instead and want to build for Linux? Zig can do it.
  </p>
</Section>
