<script>
  import ExternalLink from ':components/external-link.svelte'
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- Go -->
<Section>
  <p>If given the liberty to choose, my go-to language is always Go.</p>
  <br />
  <p>
    Over the years I've come to appreciate simple languages like
    <ExternalLink href="https://go.dev/" label="Go" />,
    <ExternalLink href="https://ziglang.org/" label="Zig" />,
    <b>C</b>
    and even
    <ExternalLink href="https://www.lua.org/" label="Lua" />
    .
    <br />
    I am of the idea that the best programming paradigm is simply the procedural
    one, a paradigm stripped of any complexity save for procedures/functions.<br
    />
    <br />
    I don't like complexity for the sake of complexity.<br />
    That is one of the reason I'm writing
    <ExternalLink
      href="https://github.com/razshare/frizzante"
      label="Frizzante"
    />
    .
  </p>
  <div class="pt-4"></div>
  <Skills
    skills={[
      {
        name: 'Frizzante',
        usage: 'primary',
        familiarity: 'high',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Go_gopher_favicon.svg/2048px-Go_gopher_favicon.svg.png',
      },
      {
        name: 'Echo',
        usage: 'primary',
        familiarity: 'high',
        image: 'https://avatars.githubusercontent.com/u/2624634?v=4',
      },
    ]}
  />
</Section>
