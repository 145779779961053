<style>
  .avatar {
    display: grid;
    justify-items: center;
  }
</style>

<div class="avatar">
  <div class="w-24 mask mask-squircle">
    <img
      alt="profile"
      src="https://avatars.githubusercontent.com/u/6891346?v=4"
    />
  </div>
</div>
