<script>
  import Router from 'svelte-spa-router'
  import Myself from ':pages/myself.svelte'
  const routes = {
    '*': Myself,
  }
</script>

<div class="root grid justify-center content-center h-full w-full">
  <Router {routes} />
</div>
