<style>
  a {
    @apply cursor-pointer;
    @apply underline;
    color: #ff9900;
  }
</style>

<script>
  /** @type {string} */
  export let href
  /** @type {string} */
  export let label = ''
  function open() {
    setTimeout(function run() {
      window.open(href)
    })
  }

  function noop() {}
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-missing-attribute -->
<a
  on:click|preventDefault={noop}
  on:mousedown|preventDefault={noop}
  on:mouseup|preventDefault={open}
>
  <slot>{label}</slot>
</a>
