<script>
  import Badge from ':components/badge.svelte'
  import ExternalLink from ':components/external-link.svelte'
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
  import { mdiInformation } from '@mdi/js'
</script>

<!-- Svelte -->
<Section>
  <p>
    <span>I like to use </span>
    <ExternalLink href="https://svelte.dev/" label="Svelte" />
    <span> a lot.</span>
    <br />
    <br />
    As
    <ExternalLink
      href="https://gist.github.com/Rich-Harris/0f910048478c2a6505d1c32185b61934"
      label="the man himself said"
    /> - yes, Svelte is a JavaScript framework... but it's also a language.
  </p>
  <div class="pt-4"></div>
  <p>
    I like to think of Svelte as a <b>DSL</b>.
  </p>
  <p>
    In the same way that <b>SQL</b> is a language specifically designed to manage
    and aggregate data in a relational database, Svelte is a language that describes
    user interfaces.
  </p>
  <div class="pt-4"></div>
  <p>
    I use Svelte to build any type of interactive single page application or
    server side rendered website.<br />
    For me it's a no brainer, I only reach out to something other than svelte if
    I'm jumping in an already existing project that doesn't use Svelte.
  </p>
  <div class="pt-4"></div>
  I've tried them all over the years, Angular, Vue, React, Solid.<br />
  There is nothing that comes even close to Svelte's ease of use and simplicity.

  <div class="pt-4"></div>
  <div class="grid w-full">
    <Badge
      variant="base"
      text="This portfolio itself is built with Svelte!"
      icon_path={mdiInformation}
    />
  </div>
  <div class="pt-4"></div>
  <Skills
    skills={[
      {
        name: 'SvelteKit',
        usage: 'primary',
        familiarity: 'high',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Svelte_Logo.svg/498px-Svelte_Logo.svg.png',
      },
    ]}
  />
</Section>
