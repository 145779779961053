<script>
  import { mdiCubeOutline } from '@mdi/js'
  import Icon from './icon.svelte'

  /** @type {string} */
  export let title
  /** @type {string} */
  export let subtitle = ''
  /** @type {string} */
  export let content = ''
  /** @type {"start"|"end"} */
  export let position
  export let icon = mdiCubeOutline
</script>

<li>
  <div class="timeline-middle">
    <Icon path={icon} />
    <div class="pt-2"></div>
  </div>
  <div
    class="mb-10 md:text-start"
    class:timeline-start={position === 'start'}
    class:timeline-end={position === 'end'}
  >
    <div
      class:md:text-end={position === 'start'}
      class:md:text-start={position === 'end'}
    >
      <h2 class="font-mono italic">{title}</h2>
      {#if subtitle}
        <div class="text-lg font-black">{subtitle}</div>
      {/if}
    </div>
    <div class="pt-4"></div>
    <slot>
      <p>{content}</p>
    </slot>
  </div>
  <hr />
</li>
