<script>
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- SQL -->
<Section>
  <p>I use SQL to read and manage my data.</p>
  <div class="pt-4"></div>
  <p>I prefer not to use <b>ORM</b>s.</p>
  <p>
    Whenever I do use an ORM, it's because a project specifically requires it.
  </p>
  <div class="pt-4"></div>
  <p>
    Whenever that happens, I try to limit the <b>N:N</b> and <b>N:1</b> relations
    I describe through the ORM itself.
  </p>
  <p>
    The only safe relations (I think) to describe through an ORM are
    <b>1:1</b> and <b>1:N</b> relations.
  </p>
  <div class="pt-4"></div>
  <p>You might wonder: <i>`How do you list things?`</i></p>
  <p>And the answer is raw <b>joins</b>.</p>
  <div class="pt-4"></div>
  <p>
    Over the years I've come to the conclusion that ORMs simply do a horrible
    job at joining tables.<br />
    It's just too much of a headache. I rather aggregate my data myself and disable
    any <b>eager</b> fetch.
  </p>
  <div class="pt-4"></div>
  <Skills
    title="Dialect"
    skills={[
      {
        name: 'MySql',
        familiarity: 'high',
        usage: 'primary',
        image: 'https://img.icons8.com/?size=256&id=qGUfLiYi1bRN&format=png',
      },
      {
        name: 'SqlServer',
        familiarity: 'medium',
        usage: 'secondary',
        image: 'https://img.icons8.com/?size=256&id=laYYF3dV0Iew&format=png',
      },
    ]}
  />
</Section>
