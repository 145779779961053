<style>
  a {
    @apply cursor-pointer;
    @apply underline;
    color: #ff9900;
  }
</style>

<script>
  /** @type {string} */
  export let href
  /** @type {string} */
  export let label = ''
</script>

<a target="_blank" {href}>
  <slot>{label}</slot>
</a>
