<style>
  .section {
    @apply absolute;
    @apply left-0;
    @apply right-0;
    @apply top-0;
    @apply bottom-0;
    @apply grid;
    @apply justify-items-center;
    @apply items-center;
    background-color: #0a070c;
    color: #b4a3a3;
    overflow: hidden;
    overflow-y: auto;
    word-break: keep-all;
  }

  .content {
    @apply grid;
    @apply grid-cols-1;
    @apply w-full;
  }
</style>

<script>
  import Timeline from ':components/timeline.svelte'
  import AboutMe from ':components/sections/about-me.svelte'
  import Zig from ':components/sections/zig.svelte'
  import Php from ':components/sections/php.svelte'
  import Svelte from ':components/sections/svelte.svelte'
  import Sql from ':components/sections/sql.svelte'
  import JavaScript from ':components/sections/javascript.svelte'
  import Java from ':components/sections/java.svelte'
  import Css from ':components/sections/css.svelte'
  import { mdiFaceManProfile, mdiLinux, mdiOpenSourceInitiative } from '@mdi/js'
  import Linux from ':components/sections/linux.svelte'
  import OpenSource from ':components/sections/open-source.svelte'
</script>

<div class="section">
  <div class="p-2">
    <div class="content">
      <Timeline let:using={{ TimelineItem }}>
        <TimelineItem
          position="start"
          title="About me"
          icon={mdiFaceManProfile}
        >
          <AboutMe />
        </TimelineItem>
        <TimelineItem position="end" title="Linux" icon={mdiLinux}>
          <Linux />
        </TimelineItem>
        <TimelineItem
          position="start"
          title="Open Source"
          icon={mdiOpenSourceInitiative}
        >
          <OpenSource />
        </TimelineItem>
        <TimelineItem position="end" title="Svelte">
          <Svelte />
        </TimelineItem>
        <TimelineItem position="start" title="Php">
          <Php />
        </TimelineItem>
        <TimelineItem position="end" title="Sql">
          <Sql />
        </TimelineItem>
        <TimelineItem position="start" title="JavaScript / TypeScript">
          <JavaScript />
        </TimelineItem>
        <TimelineItem position="end" title="Java">
          <Java />
        </TimelineItem>
        <TimelineItem position="start" title="Css">
          <Css />
        </TimelineItem>
        <TimelineItem position="end" title="Zig">
          <Zig />
        </TimelineItem>
      </Timeline>
    </div>
    <div class="pt-20"></div>
  </div>
</div>
