<style>
  .section {
    @apply relative;
    max-width: 720px;
  }
</style>

<script>
  import Icon from './icon.svelte'
  /** @type {string} */
  export let icon = ''
</script>

<div class="section">
  <slot name="header">
    {#if icon}
      <span class="inline-block"><Icon path={icon} size="3rem" /></span>
    {/if}
  </slot>
  <div class="pt-2"></div>
  <div class="p-2 pb-8 content">
    <slot></slot>
  </div>
</div>
