<style>
  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
  .header {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 480px) {
    .title {
      text-align: start;
    }
    .subtitle {
      padding-left: 0.5rem;
      text-align: start;
    }
    .header {
      grid-template-columns: auto 1fr;
      justify-items: start;
    }
  }
</style>

<script>
  import Avatar from ':components/avatar.svelte'
  import ExternalLink from ':components/external-link.svelte'
  import Icon from ':components/icon.svelte'
  import Section from ':components/section.svelte'
  import { mdiEmail, mdiGithub } from '@mdi/js'
</script>

<!-- About me -->
<Section>
  <div slot="header" class="header grid gap-2">
    <Avatar />
    <div>
      <h1 class="title">Razvan Tanase</h1>
      <h3 class="subtitle">
        <span>Fullstack Developer</span>
        <span>-</span>
        <ExternalLink href="https://github.com/razshare">
          <div
            class="inline-grid gap-2 align-bottom"
            style="grid-template-columns: auto 1fr;"
          >
            <Icon path={mdiGithub} />
            <span>GitHub</span>
          </div>
        </ExternalLink>
        <ExternalLink href="mailto:razvan@razshare.dev">
          <div
            class="inline-grid gap-2 align-bottom"
            style="grid-template-columns: auto 1fr;"
          >
            <Icon path={mdiEmail} />
            <span>razvan@razshare.dev</span>
          </div>
        </ExternalLink>
      </h3>
    </div>
  </div>
  <p>
    I'm Raz, I write software for a living as a fullstack software developer.<br
    />
    I make use of all sorts of tools and programming languages in order to achieve
    my goals, ranging from <b>front-end</b> and <b>back-end</b>
    languages to <b>systems</b> languages.
  </p>
</Section>
