<script>
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- Linux -->
<Section>
  <p>
    My development environment of choice is Linux.<br />
    It's not just a preference, I genuinely think there is
    <b>no better environment</b>
    to develop in.
  </p>
  <div class="pt-4"></div>
  <Skills
    title="Distros"
    skills={[
      {
        name: 'Ubuntu',
        usage: 'primary',
        familiarity: 'high',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo-ubuntu_cof-orange-hex.svg',
      },
      {
        name: 'Alpine',
        usage: 'secondary',
        familiarity: 'medium',
        image:
          'https://styles.redditmedia.com/t5_3cvuz/styles/communityIcon_ble5s1ni07061.png',
      },
    ]}
  />
</Section>
