<script>
  import ExternalLink from ':components/external-link.svelte'
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- Php -->
<Section>
  <p>I use Php to power up websites and web APIs.</p>
  <p>
    More often than not I reach out for
    <ExternalLink href="https://symfony.com/" label="Symfony" />
    and
    <ExternalLink href="https://github.com/razshare/catpaw" label="CatPaw" />
    .
  </p>
  <div class="pt-4"></div>
  <Skills
    skills={[
      {
        name: 'Symfony',
        usage: 'primary',
        familiarity: 'high',
        image: 'https://symfony.com/logos/symfony_black_03.svg',
      },
      {
        name: 'CatPaw',
        usage: 'primary',
        familiarity: 'high',
        image:
          'https://imgs.search.brave.com/GfxJq7Gw8AgvCBiVCCFIxGldl_jfx1r_vwGqmBtMYhA/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9waHAtMS5zdmc.svg',
      },
      {
        name: 'Laravel',
        usage: 'secondary',
        familiarity: 'medium',
        image: 'https://laravel.com/img/logomark.min.svg',
      },
    ]}
  />
</Section>
