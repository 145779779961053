<script>
  import ExternalLink from ':components/external-link.svelte'
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- Svelte -->
<Section>
  <p>
    As
    <ExternalLink
      href="https://gist.github.com/Rich-Harris/0f910048478c2a6505d1c32185b61934"
      label="the man himself said"
    />; <ExternalLink href="https://svelte.dev" label="Svelte" /> is a JavaScript
    framework... but it's also a language.
  </p>
  <div class="pt-4"></div>
  <p>
    For the most part, I use Svelte as a <ExternalLink
      href="https://en.wikipedia.org/wiki/Domain-specific_language"
      label="DSL"
    />.
  </p>
  <p>
    In the same way that <b>SQL</b> is a language specifically designed to manage
    and aggregate data in a relational database, Svelte is a language that describes
    user interfaces.
  </p>
  <div class="pt-4"></div>
  <Skills
    skills={[
      {
        name: 'SvelteKit',
        usage: 'primary',
        familiarity: 'high',
        image:
          'https://imgs.search.brave.com/ibhNabHvLVTfTZGynst17Ruuol5c64pDgcz-730bNIw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9yYXcu/Z2l0aHVidXNlcmNv/bnRlbnQuY29tL21p/Z3VlbHNvbG9yaW8v/dnNjb2RlLXN5bWJv/bHMvbWFpbi9zcmMv/aWNvbnMvZmlsZXMv/c3ZlbHRlLnN2Zw',
      },
      {
        name: 'Frizzante',
        usage: 'primary',
        familiarity: 'high',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Go_gopher_favicon.svg/2048px-Go_gopher_favicon.svg.png',
      },
    ]}
  />
</Section>
