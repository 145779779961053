<script>
  import Projects from ':components/projects.svelte'
  import Section from ':components/section.svelte'
</script>

<!-- Php -->
<Section>
  <h2>I'm an open source maintainer.</h2>
  <p>
    Open source software and code has helped me a great deal over the years, so
    I think it's only natural to give back to the community.
  </p>
  <div class="pt-4"></div>
  <p>
    So I take some time of my weekends to contribute to and maintain open source
    projects.
  </p>
  <div class="pt-8"></div>

  <Projects
    projects={[
      {
        name: 'SvelteKit SSE',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/sveltekit-sse',
        },
        role: 'maintainer',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Svelte_Logo.svg/498px-Svelte_Logo.svg.png',
      },
      {
        name: 'Svelte Liquid Swipe',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/svelte-liquid-swipe',
        },
        role: 'maintainer',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Svelte_Logo.svg/498px-Svelte_Logo.svg.png',
      },
      {
        name: 'CatPaw',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/catpaw',
        },
        role: 'maintainer',
        image:
          'https://imgs.search.brave.com/GfxJq7Gw8AgvCBiVCCFIxGldl_jfx1r_vwGqmBtMYhA/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9waHAtMS5zdmc.svg',
      },
      {
        name: 'Svelte Server Session',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/sveltekit-server-session',
        },
        role: 'maintainer',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Svelte_Logo.svg/498px-Svelte_Logo.svg.png',
      },
      {
        name: 'Psalm - VSCode',
        home: {
          label: 'GitHub',
          href: 'https://github.com/psalm/psalm-vscode-plugin',
        },
        role: 'contributor',
        image: 'https://psalm.dev/favicon.png',
      },
    ]}
  />
</Section>
