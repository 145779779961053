<script>
  import Projects from ':components/projects.svelte'
  import Section from ':components/section.svelte'
</script>

<!-- Php -->
<Section>
  <p>
    Open source software and code has helped me a great deal over the years, so
    I think it's only natural to give back to the community.
  </p>
  <div class="pt-4"></div>
  <p>
    So I take some time of my weekends to contribute to and maintain open source
    projects.
  </p>
  <div class="pt-8"></div>

  <Projects
    projects={[
      {
        name: 'Frizzante',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/frizzante',
        },
        role: 'maintainer',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Go_gopher_favicon.svg/2048px-Go_gopher_favicon.svg.png',
      },
      {
        name: 'SvelteKit SSE',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/sveltekit-sse',
        },
        role: 'maintainer',
        image:
          'https://imgs.search.brave.com/ibhNabHvLVTfTZGynst17Ruuol5c64pDgcz-730bNIw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9yYXcu/Z2l0aHVidXNlcmNv/bnRlbnQuY29tL21p/Z3VlbHNvbG9yaW8v/dnNjb2RlLXN5bWJv/bHMvbWFpbi9zcmMv/aWNvbnMvZmlsZXMv/c3ZlbHRlLnN2Zw',
      },
      {
        name: 'Svelte Liquid Swipe',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/svelte-liquid-swipe',
        },
        role: 'maintainer',
        image:
          'https://imgs.search.brave.com/ibhNabHvLVTfTZGynst17Ruuol5c64pDgcz-730bNIw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9yYXcu/Z2l0aHVidXNlcmNv/bnRlbnQuY29tL21p/Z3VlbHNvbG9yaW8v/dnNjb2RlLXN5bWJv/bHMvbWFpbi9zcmMv/aWNvbnMvZmlsZXMv/c3ZlbHRlLnN2Zw',
      },
      {
        name: 'CatPaw',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/catpaw',
        },
        role: 'maintainer',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/c/c1/PHP_Logo.png',
      },
      {
        name: 'Svelte Server Session',
        home: {
          label: 'GitHub',
          href: 'https://github.com/razshare/sveltekit-server-session',
        },
        role: 'maintainer',
        image:
          'https://imgs.search.brave.com/ibhNabHvLVTfTZGynst17Ruuol5c64pDgcz-730bNIw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9yYXcu/Z2l0aHVidXNlcmNv/bnRlbnQuY29tL21p/Z3VlbHNvbG9yaW8v/dnNjb2RlLXN5bWJv/bHMvbWFpbi9zcmMv/aWNvbnMvZmlsZXMv/c3ZlbHRlLnN2Zw',
      },
      {
        name: 'Psalm - VSCode',
        home: {
          label: 'GitHub',
          href: 'https://github.com/psalm/psalm-vscode-plugin',
        },
        role: 'contributor',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/c/c1/PHP_Logo.png',
      },
      {
        name: 'Adwaita for Steam',
        home: {
          label: 'GitHub',
          href: 'https://github.com/tkashkin/Adwaita-for-Steam',
        },
        role: 'contributor',
        image:
          'https://imgs.search.brave.com/gM_jA5UWBnLJ0yvr6GitxsaG7W4WMMy4uijYOZsJLiU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy84/LzgzL1N0ZWFtX2lj/b25fbG9nby5zdmc',
      },
    ]}
  />
</Section>
