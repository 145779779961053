<script>
  import ExternalLink from ':components/external-link.svelte'
  import Features from ':components/features.svelte'
  import Section from ':components/section.svelte'
</script>

<!-- Zig -->
<Section>
  <p>
    <ExternalLink href="https://ziglang.org/" label="Zig" /> is my language of choice
    when it comes to systems programming.
  </p>
  <br />
  <p>
    These are the main features that make Zig a better solution when compared to
    other languages like C, C++, Rust and so on.
  </p>
  <br />
  <Features
    features={[
      {
        label: 'Simple and explicit syntax',
        href: 'https://ziglang.org/documentation/master/#toc-Introduction',
      },
      {
        label: 'Cross-compilation as a first class use case',
        href: 'https://ziglang.org/learn/overview/#cross-compiling-is-a-first-class-use-case',
      },
      {
        label: 'Comptime',
        href: 'https://ziglang.org/documentation/master/#comptime',
      },
      {
        label: 'Low overhead error handling',
        href: 'https://ziglang.org/documentation/master/#Errors',
      },
      {
        label: 'Packed structs',
        href: 'https://ziglang.org/documentation/master/#packed-struct',
      },
      {
        label: 'Defer',
        href: 'https://ziglang.org/documentation/master/#defer',
      },
    ]}
  />
</Section>
