<style>
  * {
    border-color: rgb(255, 255, 255, 0.1);
  }
</style>

<script>
  import ExternalLink from ':components/external-link.svelte'

  /**
   * @typedef Feature
   * @property {string} label
   * @property {string} href
   */

  /** @type {Array<Feature>} */
  export let features
</script>

<table class="table">
  <thead>
    <tr>
      <th>Feature</th>
    </tr>
  </thead>
  <tbody>
    {#each features as feature}
      <tr>
        <td>
          <div class="flex items-center gap-3">
            <div>
              <div class="font-bold">
                <ExternalLink {...feature} />
              </div>
            </div>
          </div>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
