<script>
  import ExternalLink from ':components/external-link.svelte'
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- Php -->
<Section>
  <p>I use Php to power up websites and web APIs.</p>
  <p>
    More often than not I reach out for
    <ExternalLink href="https://symfony.com/" label="Symfony" />
    and
    <ExternalLink href="https://github.com/razshare/catpaw" label="CatPaw" />
    .
  </p>
  <div class="pt-4"></div>
  <Skills
    skills={[
      {
        name: 'Symfony',
        usage: 'primary',
        familiarity: 'high',
        image: 'https://symfony.com/logos/symfony_black_03.svg',
      },
      {
        name: 'CatPaw',
        usage: 'primary',
        familiarity: 'high',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/c/c1/PHP_Logo.png',
      },
      {
        name: 'Laravel',
        usage: 'secondary',
        familiarity: 'medium',
        image: 'https://laravel.com/img/logomark.min.svg',
      },
    ]}
  />
</Section>
