<style>
  * {
    border-color: rgb(255, 255, 255, 0.1);
  }
</style>

<script>
  /**
   * @typedef Location
   * @property {string} label
   * @property {string} href
   */
  /**
   * @typedef Skill
   * @property {string} name
   * @property {Location} home
   * @property {"contributor"|"maintainer"} role
   * @property {string} image
   */

  import ExternalLink from './external-link.svelte'

  /** @type {Array<Skill>} */
  export let projects
</script>

<table class="table">
  <thead>
    <tr>
      <th>Projects</th>
      <th>Home</th>
    </tr>
  </thead>
  <tbody>
    {#each projects as skill}
      <tr>
        <td>
          <div class="flex items-center gap-3">
            <div class="avatar">
              <div class="mask mask-squircle w-12 h-12">
                <img
                  src={skill.image}
                  alt="Logo of {skill.name.toLocaleUpperCase()}"
                />
              </div>
            </div>
            <div>
              <div class="font-bold">{skill.name.toLocaleUpperCase()}</div>
              <div class="text-sm opacity-50">
                {skill.role.toLocaleUpperCase()}
              </div>
            </div>
          </div>
        </td>
        <td><ExternalLink {...skill.home} /></td>
      </tr>
    {/each}
  </tbody>
</table>
