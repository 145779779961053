<script>
  import Section from ':components/section.svelte'
  import Skills from ':components/skills.svelte'
</script>

<!-- CSS -->
<Section>
  <p>I like writing raw CSS and SCSS.</p>
  <p>
    But I also like Tailwind CSS, so it is often the case that I mix the two.
  </p>
  <div class="pt-4"></div>
  <p>
    I usually start up with Tailwind and gradually add any raw CSS that Tailwind
    can't express in a simple manner.
  </p>
  <div class="pt-4"></div>
  <p>
    One such example would be detailed management of grids and grid areas, which
    I like to use a lot for layouts.
  </p>
  <div class="pt-4"></div>
  <p>
    More often than not I reach out for SCSS for convenience, but I have no
    issues writing traditional CSS.
  </p>
  <div class="pt-4"></div>
  <Skills
    skills={[
      {
        name: 'Tailwind',
        image:
          'https://pbs.twimg.com/profile_images/1730334391501488129/G0R0sjHH_400x400.jpg',
        usage: 'primary',
        familiarity: 'high',
      },
      {
        name: 'Materialize',
        image:
          'https://imgs.search.brave.com/NZV0wOFXSaPoCZrVLH4sQVkD_DlXDiDBPsBg_fDsTfI/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9icmFu/ZGVwcy5jb20vbG9n/by1kb3dubG9hZC9N/L01hdGVyaWFsaXpl/LUNTUy1sb2dvLXZl/Y3Rvci0wMS5zdmc',
        usage: 'secondary',
        familiarity: 'high',
      },
      {
        name: 'Pico',
        image: 'https://39ntbr6g.media.zestyio.com/logo-picocss1.jpg',
        usage: 'secondary',
        familiarity: 'medium',
      },
      {
        name: 'Bootstrap',
        image:
          'https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg',
        usage: 'secondary',
        familiarity: 'medium',
      },
    ]}
  />
</Section>
